
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "ConfComp",
  setup() {
    const router = useRouter();

    const toFrontpage = () => {
      router.push({ path: "/fhbooking" });
    };
    return { toFrontpage };
  },
});
