<template>
  <confirmation-component />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ConfirmationComponent from "../../components/confirmation/ConfComp.vue";

export default defineComponent({
  name: "Confirmation",
  components: { ConfirmationComponent },
});
</script>
