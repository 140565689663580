<template>
  <div>
    <h2>Takk for bestillingen!</h2>
    <p>Du vil motta en bekreftelse så fort vi rekker å se over bestillingen.</p>
    <button class="btn btn-primary" @click="toFrontpage()">
      Tilbake til forsiden
    </button>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "ConfComp",
  setup() {
    const router = useRouter();

    const toFrontpage = () => {
      router.push({ path: "/fhbooking" });
    };
    return { toFrontpage };
  },
});
</script>
